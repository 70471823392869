<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.product_id" v-validate="{ required: true }" id="product_id" name="product_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a product</option>
                <option v-for="(product, index) in products" :key="index" :value="product.id">{{ product.name }}</option>
              </select>
              <div class="invalid-feedback">Product name is required</div>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-2" v-if="product_consumptions.total > 0">
          <div class="col-md-6">
            <h4>Product Name: {{ product.name }}</h4>
          </div>
          <div class="col-md-6">
            <div class="float-right">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <button type="button" class="btn btn-outline-success">
                  <i class="fe fe-more-vertical" /> Download
                </button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Qty</th>
              <th>Consumption Date</th>
              <th>Remarks</th>
              <th>Hub</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product_consumption, i) in product_consumptions.data" :key="i">
              <td>{{ product_consumptions.from + i}}</td>
              <td>{{ product_consumption.amount }}</td>
              <td>{{ customDate(product_consumption.entry_date) }}</td>
              <td>{{ product_consumption.consumption_remarks }}</td>
              <td>{{ product_consumption.hub.name }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="product_consumptions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <div class="float-right">
            <pagination class="mt-2" :data="product_consumptions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      products: [],
      product_consumptions: {},
      loading: false,
      btnLoading: false,
      loader: false,
      flag: false,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        product_id: '',
        date_range: '',
      },
    }
  },
  computed: {
    product() {
      return this.product_consumptions.total > 0 ? this.products.find(element => {
        if (element.id === this.search_data.product_id) {
          return element
        }
        return null
      }) : ''
    },
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/products')
        .then(response => {
          const data = response.data
          this.products = data.products
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/report/product-consumption/search', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.product_consumptions = response.data.product_consumptions
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      this.flag = true
      this.loading = true
      apiClient.post('api/report/product-consumption/search?page=' + page, this.search_data)
        .then(response => {
          this.loading = false
          this.flag = true
          this.product_consumptions = response.data.product_consumptions
        })
    },
    excelDownload() {
      this.excelDownloadLoader = true
      apiClient.post('api/report/product-consumption/excel-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'product_consumption.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
